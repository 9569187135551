<template>
  <item-card v-if="isVisible" :is-active="selected_period.is_active">
    <!-- Menu -->
    <template v-slot:menu>
      <button class="button" @click="showMembers()">
        <span>Lihat Member</span>
        <fa-icon icon="list-ul"></fa-icon>
      </button>
      <button
        v-if="selected_period.is_active"
        class="button"
        @click="showForm()"
      >
        <span>Edit</span>
        <fa-icon icon="pencil-alt"></fa-icon>
      </button>
      <button
        v-if="selected_period.is_active && schedule.members.length === 0"
        class="button"
        @click="triggerDeleteSchedule()"
      >
        <span>Hapus</span>
        <fa-icon icon="trash-alt"></fa-icon>
      </button>
    </template>

    <!-- Contents -->
    <section class="grid grid-cols-2 gap-4">
      <div>
        <span class="subtle-label">mulai</span>
        <span class="content">{{
          schedule.started_at | indonesian_datetime_format
        }}</span>
      </div>
      <div>
        <span class="subtle-label">selesai</span>
        <span class="content">{{
          schedule.ended_at | indonesian_datetime_format
        }}</span>
      </div>
      <div>
        <span class="subtle-label">kuota</span>
        <span class="content">{{ schedule.filled }}/{{ schedule.quota }}</span>
      </div>
      <div>
        <span class="subtle-label">ruangan</span>
        <span class="content">{{ schedule.location }}</span>
      </div>
    </section>
  </item-card>
</template>


<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      isVisible: true,
    };
  },
  props: {
    schedule: {
      required: true,
      type: Object,
    },
  },
  methods: {
    /** Hide this component when the ID is not in excluded list */
    hide({ excluded_id }) {
      if (this._uid !== excluded_id) {
        this.isVisible = false;
      }
    },

    /** Show this component */
    show() {
      this.isVisible = true;
    },

    /** Show schedule form */
    showForm() {
      this.SET_FORM_DATA(this.schedule);
      this.SHOW_FORM();
      this.hideMenu();
    },

    /** Show member list */
    showMembers() {
      this.hideMenu();
      this.hideOthers({ excluded_id: this._uid });
      this.SHOW_MEMBER_LIST();
      this.SET_SELECTED_SCHEDULE(this.schedule);
    },

    /** Hide Menu */
    hideMenu: () => EventBus.$emit("item-card.menu.hide"),

    /** Hide othre schedule items */
    hideOthers: (options) => EventBus.$emit("schedule.item.hide", options),

    /** Trigger delete action */
    async triggerDeleteSchedule() {
      if (this.schedule.members.length > 0) {
        this.$toasted.error("Jadwal sudah terisi, tidak dapat dihapus");
        return;
      }
      await this.deleteSchedule(this.schedule);
      this.HIDE_MEMBER_LIST();
    },

    /** Actions from schedule store */
    ...mapActions("schedule", ["deleteSchedule"]),

    /** Mutations from schedule store */
    ...mapMutations("schedule", [
      "SHOW_FORM",
      "SET_SELECTED_SCHEDULE",
      "SET_FORM_DATA",
    ]),

    /** Mutations from member store */
    ...mapMutations("member", {
      SHOW_MEMBER_LIST: "SHOW_LIST",
      HIDE_MEMBER_LIST: "HIDE_LIST",
    }),
  },
  computed: {
    /** Getters from period store */
    ...mapGetters("period", ["selected_period"]),
  },
  mounted() {
    EventBus.$on("schedule.item.show", this.show);
    EventBus.$on("schedule.item.hide", this.hide);
  },
  name: "ScheduleListItem",
  components: { ItemCard: () => import("@/components/Common/ItemCard.vue") },
};
</script>